<template>
    <b-card no-body border-variant="primary">
        <b-card-body class="py-1">
            <div
                class="d-lg-flex align-items-center"
            >

                <div class="d-flex justify-content-center align-content-center mr-1">
                    <b-avatar :src="element.photoURL" size="5rem" class="badge-light-danger" v-if="element.photoURL"  rounded />
                </div>
            <div class="flex-grow-1">
                <h4 class="cursor-pointer">{{ element.Item }}</h4>
                <div class="d-lg-flex flex-column">
                    <p v-if="element.Nature" class="mr-1 mb-0">
                        <strong>{{ newFurnitureElement ? i18nT(`Revêtement`) : i18nT(`Matériau`)}}: </strong> {{ element.Nature }}
                    </p>
                    <p class="mb-0 mr-1">
                        <strong>{{ i18nT(`Etat d'usure: `) }}</strong>
                        {{ i18nT(wearStateText) }}
                    </p>
                    <p v-if="newFurniture" class="mb-0">
                        <strong>{{ i18nT(`Fonctionnement: `) }}</strong>
                        <b-badge pill variant="light-secondary">{{ i18nT(operationText)}}</b-badge>
                    </p>
                </div>
                <p v-if="element.Comments" class="mb-0 mt-50">
                    {{ element.Comments }}
                </p>
            </div>
            <div class="float-right">
                <b-button-group
                    size="sm"
                >
                    <b-button
                        variant="outline-primary"
                        @click="editItem"
                    >
                        <rt-icon icon="edit" variant="light" :size="16"/>
                    </b-button>
                    <b-button
                        variant="outline-primary"
                        @click="deleteItem"
                    >
                        <rt-icon icon="trash" variant="light" :size="16"/>
                    </b-button>
                </b-button-group>
                    </div>
            </div>
        </b-card-body>
    </b-card>

</template>


<script>

import {
    BCard,
    BCardBody,
    BCol,
    BRow,
    BAvatar,
} from "bootstrap-vue";


export default {
    components: {
        BCardBody,
        BRow,
        BCard,
        BCol,
        BAvatar,
        },
    props: {
        element: {
            type: Object,
            default: null,
        },
        newFurniture: {
            type: Boolean,
            default: false,
        },
        newFurnitureElement: {
            type: Boolean,
            default: false,
        },
        roomId: {
            type: Number | String,
            default: null
        },
        itemIndex: {
            type: Number,
            defalut: null
        }
    },
    data(){
        return {
            operationOptions: [
                {value: 0, text: "Non vérifié"},
                {value: 1, text: "Fonctionne"},
                {value: 2, text: "Hors service"},
            ],
            wearStateOptions: [
                {value: 0, text: "Non vérifié"},
                {value: 1, text: "Neuf"},
                {value: 2, text: "Bon état"},
                {value: 3, text: "Etat moyen"},
                {value: 4, text: "Mauvais état"},
            ],
            operationText: null,
            wearStateText: null,
        };
    },
    methods: {
        deleteItem() {
            this.$emit('delete', this.roomId, this.newFurnitureElement ? "newRoomFurnitureElement" : "newRoomFurniture", this.itemIndex)
        },
        editItem() {
            this.$emit('edit', this.element, this.roomId, this.newFurnitureElement ? "newRoomFurnitureElement" : "newRoomFurniture", this.itemIndex)
        }
    },
    watch: {
        element: {
            handler: function (newVal) {

                if(this.newFurniture){
                    const operation = this.operationOptions.find(option => option.value == newVal.Operation);
                    if(operation) {
                        this.operationText = operation.text;
                    }
                }

                const wearState = this.wearStateOptions.find(option => option.value == newVal.WearState);
                if(wearState) {
                    this.wearStateText = wearState.text;
                }

            },
            immediate: true
        }
    }

}
</script>
<style>
.room-item-img{
    object-fit: cover !important;
}
</style>
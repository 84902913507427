<template>
    <div>
        <ellipsis-dropdown 
            componentName="handovers"
            :showDeleteBtn="curInventoryId !== undefined"
            @save="validationForm"
            @delete="onDelete"
        />
        <validation-observer ref="simpleRules">
        <b-row>
            <b-col cols="12" md="3">
<!--                NAVBAR-->
                <b-nav vertical class="navbar-custom align-items-start">
                    <b-nav-item @click.prevent="changeTab('general-info')" class="b-nav-pills-custom w-100" :class="{'b-nav-pills-custom-active': active=='general-info'}">
                        <div class="d-flex align-items-center mb-1 mr-1 ren-title"
                            :class="{'ren-title-active': active==='general-info'}"
                        >
                            <div 
                                class="ren-title-img"
                                :class="{'bg-primary': active==='general-info'}"
                            >
                                <RtIcon :size="21" variant="light" icon="circle-info"/>
                            </div>
                            <div>
                            <p class="m-0">
                                <strong>{{ i18nT(`Informations générales`) }}</strong>
                            </p>
                            <p v-if="false" class="m-0 text-secondary">
                                {{ i18nT(`Type, noms, addresse`)}}
                            </p>
                            </div>
                        </div>
                    </b-nav-item>
                    <b-nav-item @click.prevent="changeTab('observations')" class="b-nav-pills-custom w-100" :class="{'b-nav-pills-custom-active': active=='observations'}">
                        <div class="d-flex align-items-center mb-1 mr-1 ren-title"
                            :class="{'ren-title-active': active==='observations'}"
                        >
                            <div 
                                class="ren-title-img"
                                :class="{'bg-primary': active==='observations'}"
                            >
                                <RtIcon :size="21" variant="light" icon="note"/>
                            </div>
                            <div>
                            <p class="m-0">
                                <strong>{{ i18nT(`Observations`) }}</strong>
                            </p>
                            <p v-if="false" class="m-0 text-secondary">
                                {{ i18nT(`Type, noms, addresse`)}}
                            </p>
                            </div>
                        </div>
                    </b-nav-item>
                    <b-nav-item 
                        class="b-nav-pills-custom w-100 p-0" 
                        v-b-toggle='"isOpenRoomsTab"'
                        :class="{'collapsed': isOpenRoomsTab, 'rt-rooms-tab': isOpenRoomsTab}"
                        :aria-expanded="isOpenRoomsTab ? 'true' : 'false'"
                    >
                        <div class="d-flex align-items-center ren-title">
                                <div 
                                    class="ren-title-img"
                                >
                                    <RtIcon :size="21" variant="light" icon="house"/>
                                </div>
                                <div class="d-flex justify-content-start">
                                    <p class="m-0">
                                        <strong>{{ i18nT(`Rooms`) }}</strong>
                                    </p>
                                    <p v-if="false" class="m-0 text-secondary">
                                        {{ i18nT(`Type, noms, addresse`) }}
                                    </p>
                                </div>
                                <span class="ml-auto">
                                <rt-icon
                                    :icon="isOpenRoomsTab ? 'chevron-down' : 'chevron-right'"
                                    :size="12"
                                    variant="light mr-50 pt-50"
                                />
                                </span>
                        </div>
                    </b-nav-item>
                    <b-collapse id="isOpenRoomsTab" v-model="isOpenRoomsTab">
                        <draggable tag="ul" v-model='tabs'
                                   class="flex-wrap w-100 cursor-move pt-1 pl-50"  
                                   draggable=".ren-room-tab"
                            >
                            <b-nav-item @click.prevent='changeTab(index)'
                                        tag="li"
                                        v-for="(tab, index) in tabs"
                                        :key="'room'+ tab.id"
                                        class="ren-room-tab b-nav-pills-custom w-100">
                                
                                    <div class="d-flex align-items-center mb-75 mr-1 ren-title"
                                        :class="{'ren-title-active': active.id===tab.id}"
                                    >
                                        <div 
                                            class="ren-title-img"
                                            :class="{'bg-primary': active.id===tab.id}"
                                        >
                                            <RtIcon :size="21" variant="light" :icon="tab.icon"/>
                                        </div>
                                        <div>
                                        <p class="m-0">
                                            <strong>{{ tab.title }}</strong>
                                        </p>
                                        <p v-if="false" class="m-0 text-secondary">
                                            {{ i18nT(`Type, noms, addresse`)}}
                                        </p>
                                        </div>
                                    </div>
                                </b-nav-item>
                            <b-nav-item class='ren-add-tab-button mt-2 mb-2' @click="addTab">
                                <div
                                     class="d-flex cursor-pointer">
                                    <div class="text-white">
                                        <RtIcon :size="18" variant="light" icon="circle-plus"/>
                                    </div>
                                    <div class="ml-50">
                                        <p class="m-0 text-white text-nowrap">
                                            <strong>{{ i18nT(`Nouvelle pièce`) }}</strong>
                                        </p>
                                    </div>
                                </div>
                            </b-nav-item>
                        </draggable>
                    </b-collapse>
                </b-nav>
            </b-col>
<!--            GENERAL INFO-->
                <b-col cols="12" md="9">
                <div v-if="active === 'general-info'">
                    <b-card>
                        <b-card-header class="p-0"
                        >
                            <h2>
                                {{i18nT(`Informations générales`)}}
                            </h2>
                        </b-card-header>
                        <b-row class="mx-1 mb-2 pb-2">

                            <b-col
                                cols="12"
                            >
                                <b-row class="mt-3">
                                    <b-col
                                        cols="12" md="6"
                                    >
                                        <b-form-group
                                            :label="i18nT(`Type`)"
                                        >
                                            <validation-provider
                                                :name="i18nT(`type`)"
                                            >
                                                <v-select
                                                    v-model="inventoryData.HandoverType"
                                                    :options="inventoryTypes"
                                                    label="text"
                                                    :clearable="false"
                                                    :reduce="(e) => (e.value)"
                                                    class="mb-1 required"
                                                    :placeholder="i18nT(`Choisir`)"
                                                />
                                            </validation-provider>
                                        </b-form-group>
                                    </b-col>
                                </b-row>
                                <b-row >
                                    <b-col
                                        cols="12" md="6">
                                        <b-form-group
                                            :label="i18nT(`Identifiant`)"
                                            class="required"
                                        >
                                            <validation-provider
                                                :name="i18nT(`Identifiant`)"
                                                #default="{ errors }"
                                                rules="required"
                                            >
                                                <b-form-input
                                                    id="Identifier"
                                                    v-model="inventoryData.Name"
                                                    :state="errors.length > 0 ? false:null"
                                                    :placeholder="i18nT(`Nouvel état des lieux`)"
                                                />
                                                <small class="text-danger">{{ i18nT(errors[0]) }}</small>
                                            </validation-provider>
                                            <small class="ml-50">{{ i18nT(`Donner un identifiant unique à votre état des lieux.`) }}</small>

                                        </b-form-group>
                                    </b-col>
                                </b-row>
                                <b-row>
                                    <b-col
                                        cols="12" md="6"
                                    >
                                        <b-form-group
                                            :label="i18nT(`Location`)"
                                        >
                                            <v-select
                                                v-model="leaseAndReservation"
                                                :options="leasesAndReservations"
                                                label="text"
                                                :clearable="false"
                                                :reduce="(e) => +(e.value)"
                                                :placeholder="i18nT(`Pas lié à une location ou une réservation`)"
                                                :selectable="selected => selected.disabled !== true"
                                            />
                                            <small class="ml-50">{{i18nT(`Choisir une location ou une réservation.`)}}</small>
                                        </b-form-group>
                                    </b-col>
                                </b-row>
                            </b-col>
                        </b-row>
                        <b-row class="border-bottom">
                            <b-col
                                cols="12"
                            >
                                <h4 class="mb-2 mt-2">
                                    {{i18nT(`Relevés des compteurs eau, gaz...`)}}
                                </h4>
                            </b-col>
                        </b-row>
                        <b-row class="mx-1 mb-2">
                            <b-col cols="12" md="12">
                                <b-row :class="{'mt-3': index === 0}"
                                        v-if="false"
                                       clas="border-bottom"
                                       v-for="(item,index) in swm"
                                       :key="'key+' + index"
                                >
                                    <b-col
                                        cols="12"
                                        md="3"
                                        class="mt-1"
                                    >
                                        <b-form-group :label="i18nT(`Type de relevé`)">
                                            <b-form-input
                                                v-model="item.Type"
                                            />
                                        </b-form-group>
                                    </b-col>
                                    <b-col
                                        cols="12"
                                        md="2"
                                        class="mt-1"
                                    >
                                        <b-form-group :label="i18nT(`No. de serie`)">
                                            <b-form-input
                                                id="number"
                                                type="number"
                                                v-model="item.SerialNo"
                                            />
                                        </b-form-group>
                                    </b-col>
                                    <b-col
                                        cols="12"
                                        md="1"
                                        class="mt-1"
                                    >
                                        <b-form-group :label="i18nT(`M3`)">
                                            <b-form-input
                                                id="quantity"
                                                type="number"
                                                v-model="item.M3"
                                            />
                                        </b-form-group>
                                    </b-col>
                                    <b-col
                                        cols="12"
                                        md="3"
                                        class="mt-1"
                                    >
                                        <b-form-group :label="i18nT(`Fonctionnement`)" >
                                            <v-select
                                                v-model="item.Operation"
                                                :options="operationOptions"
                                                label="text"
                                                :clearable="false"
                                                :reduce="(e) => +(e.value)"
                                            />
                                        </b-form-group>
                                    </b-col>
                                    <b-col
                                        cols="12"
                                        md="3"
                                        class="mt-1"
                                    >
                                        <b-form-group :label="i18nT(`Commentaire`)">
                                            <b-form-input
                                                id="comment"
                                                v-model="item.Comments"
                                            />
                                        </b-form-group>
                                    </b-col>
                                    <b-col
                                        cols="12"
                                        class="text-right mt-1"
                                    >
                                        <b-button
                                            variant="outline-danger"
                                            @click="removeGeneralItem(index, 'swm')"
                                        >
                                            {{i18nT(`Supprimer l'élément`)}}
                                        </b-button>
                                    </b-col>
                                </b-row>
                                <b-row 
                                    v-for="(item,index) in swm"
                                    :key="'key+' + index"
                                    :class="{'mt-3': index === 0}"
                                >
                                    <room-general-info-card 
                                        :elementType="item.type" 
                                        :element="item" 
                                        @edit="editGeneralInfo($event, index, 'swm')"
                                        @delete="removeGeneralItem" 
                                        class="w-100"
                                    />
                                </b-row>
                                <b-row
                                    class="pt-2"
                                >
                                    <b-col
                                        cols="12"
                                        md="9"
                                    >
                                        <b-button
                                            @click="openGeneralItemModal('swm')"
                                            variant="outline-primary"
                                        >
                                            <rt-icon icon="circle-plus" variant="light"/>
                                            {{i18nT(` Ajouter un autre relevé eau, gaz`)}}
                                        </b-button>
                                    </b-col>
                                </b-row>
                            </b-col>
                        </b-row>
                        <b-row class="border-bottom">
                            <b-col
                                cols="12"
                            >
                                <h4 class="mb-2 mt-2">
                                    {{i18nT(`Relevé compteur électrique`)}}
                                </h4>
                            </b-col>
                        </b-row>
                        <b-row class="mx-1 mb-2 pb-2">
                            <b-col cols="12" md="12">
                                <b-row 
                                    v-for="(item,index) in emr"
                                    :key="'key+' + index"
                                    :class="{'mt-3': index === 0}"
                                >
                                    <room-general-info-card 
                                        :elementType="item.type" 
                                        :element="item" 
                                        @edit="editGeneralInfo($event, index, 'emr')"
                                        @delete="removeGeneralItem" 
                                        class="w-100"
                                    />                                </b-row>
                                <b-row :class="{'mt-3': index === 0}"
                                       clas="border-bottom"
                                       v-if="false"
                                       v-for="(item,index) in emr"
                                       :key="'key+' + index"
                                >
                                    <b-col
                                        cols="12"
                                        md="3"
                                        class="mt-1"
                                    >
                                        <b-form-group :label="i18nT(`Type de relevé`)">
                                            <b-form-input
                                                v-model="item.Type"
                                            />
                                        </b-form-group>
                                    </b-col>
                                    <b-col
                                        cols="12"
                                        md="2"
                                        class="mt-1"
                                    >
                                        <b-form-group :label="i18nT(`No. de serie`)">
                                            <b-form-input
                                                id="number"
                                                type="number"
                                                v-model="item.SerialNo"
                                            />
                                        </b-form-group>
                                    </b-col>
                                    <b-col
                                        cols="12"
                                        md="1"
                                        class="mt-1"
                                    >
                                        <b-form-group :label="i18nT(`KWH`)">
                                            <b-form-input
                                                id="quantity"
                                                type="number"
                                                v-model="item.M3"
                                            />
                                        </b-form-group>
                                    </b-col>
                                    <b-col
                                        cols="12"
                                        md="3"
                                        class="mt-1"
                                    >
                                        <b-form-group :label="i18nT(`Fonctionnement`)" >
                                            <v-select
                                                v-model="item.Operation"
                                                :options="operationOptions"
                                                label="text"
                                                :clearable="false"
                                                :reduce="(e) => +(e.value)"
                                            />
                                        </b-form-group>
                                    </b-col>
                                    <b-col
                                        cols="12"
                                        md="3"
                                        class="mt-1"
                                    >
                                        <b-form-group :label="i18nT(`Commentaire`)">
                                            <b-form-input
                                                id="comment"
                                                v-model="item.Comments"
                                            />
                                        </b-form-group>
                                    </b-col>
                                    <b-col
                                        cols="12"
                                        class="text-right mt-1"
                                    >
                                        <b-button
                                            variant="outline-danger"
                                            @click="removeGeneralItem(index, 'emr')"
                                        >
                                            {{i18nT(`Supprimer l'élément`)}}
                                        </b-button>
                                    </b-col>
                                </b-row>
                                <b-row
                                    class="pt-2"
                                >
                                    <b-col
                                        cols="12"
                                        md="9"
                                    >
                                        <b-button
                                            @click="openGeneralItemModal('emr')"
                                            variant="outline-primary"
                                        >
                                            <rt-icon icon="circle-plus" variant="light"/>
                                            {{i18nT(`Ajouter un autre relevé électrique`)}}
                                        </b-button>
                                    </b-col>
                                </b-row>
                            </b-col>
                        </b-row>
                        <b-row class="border-bottom">
                            <b-col
                                cols="12"
                            >
                                <h4 class="mb-2 mt-2">
                                    {{i18nT(`Type de chauffage`)}}
                                </h4>
                            </b-col>
                        </b-row>
                        <b-row class="mx-1 mb-2 pb-2">
                            <b-col cols="12" md="12">
                                <b-row 
                                    v-for="(item,index) in th"
                                    :key="'key+' + index"
                                    :class="{'mt-3': index === 0}"
                                >
                                    <room-general-info-card 
                                        :elementType="item.type" 
                                        :element="item" 
                                        @edit="editGeneralInfo($event, index, 'th')"
                                        @delete="removeGeneralItem" 
                                        class="w-100"
                                    />                                </b-row>
                                <b-row :class="{'mt-3': index === 0}"
                                       clas="border-bottom"
                                       v-if="false"
                                       v-for="(item,index) in th"
                                       :key="'key+' + index"
                                >
                                    <b-col
                                        cols="12"
                                        md="3"
                                        class="mt-1"
                                    >
                                        <b-form-group :label="i18nT(`Type de relevé`)">
                                            <b-form-input
                                                v-model="item.Type"
                                            />
                                        </b-form-group>
                                    </b-col>
                                    <b-col
                                        cols="12"
                                        md="2"
                                        class="mt-1"
                                    >
                                        <b-form-group :label="i18nT(`No. de serie`)">
                                            <b-form-input
                                                id="number"
                                                type="number"
                                                v-model="item.SerialNo"
                                            />
                                        </b-form-group>
                                    </b-col>
                                    <b-col
                                        cols="12"
                                        md="1"
                                        class="mt-1"
                                    >
                                        <b-form-group :label="i18nT(`M3/KWH`)">
                                            <b-form-input
                                                id="quantity"
                                                type="number"
                                                v-model="item.M3"
                                            />
                                        </b-form-group>
                                    </b-col>
                                    <b-col
                                        cols="12"
                                        md="3"
                                        class="mt-1"
                                    >
                                        <b-form-group :label="i18nT(`Fonctionnement`)" >
                                            <v-select
                                                v-model="item.Operation"
                                                :options="operationOptions"
                                                label="text"
                                                :clearable="false"
                                                :reduce="(e) => +(e.value)"
                                            />
                                        </b-form-group>
                                    </b-col>
                                    <b-col
                                        cols="12"
                                        md="3"
                                        class="mt-1"
                                    >
                                        <b-form-group :label="i18nT(`Commentaire`)">
                                            <b-form-input
                                                id="comment"
                                                v-model="item.Comments"
                                            />
                                        </b-form-group>
                                    </b-col>
                                    <b-col
                                        cols="12"
                                        class="text-right mt-1"
                                    >
                                        <b-button
                                            variant="outline-danger"
                                            @click="removeGeneralItem(index, 'th')"
                                        >
                                            {{i18nT(`Supprimer l'élément`)}}
                                        </b-button>
                                    </b-col>
                                </b-row>
                                <b-row
                                    class="pt-2"
                                >
                                    <b-col
                                        cols="12"
                                        md="9"
                                    >
                                        <b-button
                                            @click="openGeneralItemModal('th')"
                                            variant="outline-primary"
                                        >
                                            <rt-icon icon="circle-plus" variant="light"/>
                                            {{i18nT(`Ajouter un autre chauffage`)}}
                                        </b-button>
                                    </b-col>
                                </b-row>
                            </b-col>
                        </b-row>
                        <b-row class="border-bottom">
                            <b-col
                                cols="12"
                            >
                                <h4 class="mb-2 mt-2">
                                    {{i18nT(`Production d’eau chaude`)}}
                                </h4>
                            </b-col>
                        </b-row>
                        <b-row class="mx-1 mb-2 pb-2">
                            <b-col cols="12" md="12">
                                <b-row 
                                    v-for="(item,index) in hw"
                                    :key="'key+' + index"
                                    :class="{'mt-3': index === 0}"
                                >
                                    <room-general-info-card 
                                        :elementType="item.type" 
                                        :element="item" 
                                        @edit="editGeneralInfo($event, index, 'hw')"
                                        @delete="removeGeneralItem" 
                                        class="w-100"
                                    />                                </b-row>
                                <b-row :class="{'mt-3': index === 0}"
                                       clas="border-bottom"
                                       v-if="false"
                                       v-for="(item,index) in hw"
                                       :key="'key+' + index"
                                >
                                    <b-col
                                        cols="12"
                                        md="3"
                                        class="mt-1"
                                    >
                                        <b-form-group :label="i18nT(`Type production`)">
                                            <b-form-input
                                                v-model="item.Type"
                                            />
                                        </b-form-group>
                                    </b-col>
                                    <b-col
                                        cols="12"
                                        md="3"
                                        class="mt-1"
                                    >
                                        <b-form-group :label="i18nT(`Etat d'usure`)" >
                                            <v-select
                                                v-model="item.WearState"
                                                :options="wearStateOptions"
                                                label="text"
                                                :clearable="false"
                                                :reduce="(e) => +(e.value)"
                                            />
                                        </b-form-group>
                                    </b-col>
                                    <b-col
                                        cols="12"
                                        md="3"
                                        class="mt-1"
                                    >
                                        <b-form-group :label="i18nT(`Fonctionnement`)" >
                                            <v-select
                                                v-model="item.Operation"
                                                :options="operationOptions"
                                                label="text"
                                                :clearable="false"
                                                :reduce="(e) => +(e.value)"
                                            />
                                        </b-form-group>
                                    </b-col>
                                    <b-col
                                        cols="12"
                                        md="3"
                                        class="mt-1"
                                    >
                                        <b-form-group :label="i18nT(`Commentaire`)">
                                            <b-form-input
                                                id="comment"
                                                v-model="item.Comments"
                                            />
                                        </b-form-group>
                                    </b-col>
                                    <b-col
                                        cols="12"
                                        class="text-right mt-1"
                                    >
                                        <b-button
                                            variant="outline-danger"
                                            @click="removeGeneralItem(index, 'hw')"
                                        >
                                            {{i18nT(`Supprimer l'élément`)}}
                                        </b-button>
                                    </b-col>
                                </b-row>
                                <b-row
                                    class="pt-2"
                                >
                                    <b-col
                                        cols="12"
                                        md="9"
                                    >
                                        <b-button
                                            @click="openGeneralItemModal('hw')"
                                            variant="outline-primary"
                                        >
                                            <rt-icon icon="circle-plus" variant="light"/>
                                            {{i18nT(`Ajouter un autre élément`)}}
                                        </b-button>
                                    </b-col>
                                </b-row>
                            </b-col>
                        </b-row>
                        <b-row class="border-bottom">
                            <b-col
                                cols="12"
                            >
                                <h4 class="mb-2 mt-2">
                                    {{i18nT(`Remise des clés`)}}
                                </h4>
                            </b-col>
                        </b-row>
                        <b-row class="mx-1 mb-2 pb-2">
                            <b-col cols="12" md="12">
                                <b-row 
                                    v-for="(item,index) in key"
                                    :key="'key+' + index"
                                    :class="{'mt-3': index === 0}"
                                >
                                    <room-general-info-card 
                                        :elementType="item.type" 
                                        :element="item" 
                                        @edit="editGeneralInfo($event, index, 'key')"
                                        @delete="removeGeneralItem" 
                                        class="w-100"
                                    />                                </b-row>
                                <b-row :class="{'mt-3': index === 0}"
                                       clas="border-bottom"
                                       v-if="false"
                                       v-for="(item,index) in key"
                                       :key="'key+' + index"
                                >
                                    <b-col
                                        cols="12"
                                        md="3"
                                        class="mt-1"
                                    >
                                        <b-form-group :label="i18nT(`Type clé`)">
                                            <b-form-input
                                                v-model="item.Type"
                                            />
                                        </b-form-group>
                                    </b-col>
                                    <b-col
                                        cols="12"
                                        md="2"
                                        class="mt-1"
                                    >
                                        <b-form-group :label="i18nT(`Nombre`)">
                                            <b-form-input
                                                id="number"
                                                type="number"
                                                v-model="item.Count"
                                            />
                                        </b-form-group>
                                    </b-col>
                                    <b-col
                                        cols="12"
                                        md="2"
                                        class="mt-1"
                                    >
                                        <b-form-group :label="i18nT(`Remis`)">
                                            <b-form-input
                                                id="quantity"
                                                v-model="item.Given"
                                            />
                                        </b-form-group>
                                    </b-col>
                                    <b-col
                                        cols="12"
                                        md="2"
                                        class="mt-1"
                                    >
                                        <b-form-group :label="i18nT(`Date`)" >
                                            <date-picker
                                                v-model="item.Date"
                                                :manual-input="true"
                                                reset-button
                                            />
                                        </b-form-group>
                                    </b-col>
                                    <b-col
                                        cols="12"
                                        md="3"
                                        class="mt-1"
                                    >
                                        <b-form-group :label="i18nT(`Commentaire`)">
                                            <b-form-input
                                                id="comment"
                                                v-model="item.Comments"
                                            />
                                        </b-form-group>
                                    </b-col>
                                    <b-col
                                        cols="12"
                                        class="text-right mt-1"
                                    >
                                        <b-button
                                            variant="outline-danger"
                                            @click="removeGeneralItem(index,'key')"
                                        >
                                            {{i18nT(`Supprimer l'élément`)}}
                                        </b-button>
                                    </b-col>
                                </b-row>
                                <b-row
                                    class="pt-2"
                                >
                                    <b-col
                                        cols="12"
                                        md="9"
                                    >
                                        <b-button
                                            @click="openGeneralItemModal('key')"
                                            variant="outline-primary"
                                        >
                                            <rt-icon icon="circle-plus" variant="light"/>
                                            {{i18nT(`Ajouter une autre clé`)}}
                                        </b-button>
                                    </b-col>
                                </b-row>


                            </b-col>
                        </b-row>
                        <b-row class="border-bottom">
                            <b-col
                                cols="12"
                            >
                                <h4 class="mb-2 mt-2">
                                    {{i18nT(`Autres aménagements`)}}
                                </h4>
                            </b-col>
                        </b-row>
                        <b-row class="mx-1 mb-2 pb-2 border-bottom">
                            <b-col cols="12" md="12">
                                <b-row 
                                    v-for="(item, index) in oth"
                                    :key="'key+' + index"
                                    :class="{'mt-3': index === 0}"
                                >
                                    <room-general-info-card 
                                        :elementType="item.type" 
                                        :element="item" 
                                        @edit="editGeneralInfo($event, index, 'oth')"
                                        @delete="removeGeneralItem" 
                                        class="w-100"
                                    />                                </b-row>
                                <b-row :class="{'mt-3': index === 0}"
                                       clas="border-bottom"
                                       v-if="false"
                                       v-for="(item,index) in oth"
                                       :key="'key+' + index"
                                >
                                    <b-col
                                        cols="12"
                                        md="3"
                                        class="mt-1"
                                    >
                                        <b-form-group :label="i18nT(`Element`)">
                                            <b-form-input
                                                v-model="item.Type"
                                            />
                                        </b-form-group>
                                    </b-col>
                                    <b-col
                                        cols="12"
                                        md="2"
                                        class="mt-1"
                                    >
                                        <b-form-group :label="i18nT(`Matériau`)">
                                            <b-form-input
                                                v-model="item.SerialNo"
                                            />
                                        </b-form-group>
                                    </b-col>
                                    <b-col
                                        cols="12"
                                        md="2"
                                        class="mt-1"
                                    >
                                        <b-form-group :label="i18nT(`Etat d'usure`)">
                                            <v-select
                                                v-model="item.WearState"
                                                :options="wearStateOptions"
                                                label="text"
                                                :clearable="false"
                                                :reduce="(e) => +(e.value)"
                                                class="text-nowrap"
                                            />
                                        </b-form-group>
                                    </b-col>
                                    <b-col
                                        cols="12"
                                        md="2"
                                        class="mt-1"
                                    >
                                        <b-form-group :label="i18nT(`Fonctionnement`)" >
                                            <v-select
                                                v-model="item.Operation"
                                                :options="operationOptions"
                                                label="text"
                                                :clearable="false"
                                                :reduce="(e) => +(e.value)"
                                            />
                                        </b-form-group>
                                    </b-col>
                                    <b-col
                                        cols="12"
                                        md="3"
                                        class="mt-1"
                                    >
                                        <b-form-group :label="i18nT(`Commentaire`)">
                                            <b-form-input
                                                id="comment"
                                                v-model="item.Comments"
                                            />
                                        </b-form-group>
                                    </b-col>
                                    <b-col
                                        cols="12"
                                        class="text-right mt-1"
                                    >
                                        <b-button
                                            variant="outline-danger"
                                            @click="removeGeneralItem(index, 'oth')"
                                        >
                                            {{i18nT(`Supprimer l'élément`)}}
                                        </b-button>
                                    </b-col>
                                </b-row>
                                <b-row
                                    class="pt-2"
                                >
                                    <b-col
                                        cols="12"
                                        md="9"
                                    >
                                        <b-button
                                            @click="openGeneralItemModal('oth')"
                                            variant="outline-primary"
                                        >
                                            <rt-icon icon="circle-plus" variant="light"/>
                                            {{i18nT(`Ajouter un autre élément`)}}
                                        </b-button>
                                    </b-col>
                                </b-row>


                            </b-col>


                        </b-row>
                        <b-row>
                            <b-col cols="6" class="text-muted">
                                <div  class="m-1 pl-1">
                                    <div v-if="inventoryData.PropertyCreateTime">
                                        <span>
                                            <rt-icon icon="calendar-plus" variant="light"/>
                                        </span>
                                        <small>
                                            {{ i18nT(`Créé le`) }} {{ inventoryData.PropertyCreateTime }}
                                        </small>
                                    </div>
                                    <div v-if="inventoryData.PropertyLastEditDate">
                                        <span>
                                            <rt-icon icon="calendar-pen" variant="light"/>
                                        </span>
                                        <small>
                                            {{ i18nT(`Modifié le `) }} {{ inventoryData.PropertyLastEditDate }}
                                        </small>
                                    </div>
                                </div>
                            </b-col>
                            <b-col cols="6">
                                <div class="text-right m-1">
                                    <b-button
                                        variant="outline-secondary"
                                        class="mb-1 mr-1"
                                        :to="{ name: 'handovers'}"
                                    >
                                        {{i18nT(`Annuler`)}}
                                    </b-button>
                                    <b-button
                                        variant="primary"
                                        class="mb-1"
                                        @click.prevent="validationForm"
                                    >
                                        {{i18nT(`Sauvegarder`)}}
                                    </b-button>
                                </div>
                            </b-col>
                        </b-row>


                    </b-card>
                </div>
<!--                    NOTES-->
                <div v-else-if="active === 'observations'">
                    <b-card>
                        <b-card-header class="p-0"
                        >
                            <h2>
                                {{i18nT(`Observations`)}}
                            </h2>
                        </b-card-header>
                        <b-row class="mb-2 mt-2 border-bottom pb-2">
                            <b-col
                                cols="12"
                            >
                                <b-form-textarea
                                    rows="5"
                                    v-model="notes"
                                />
                            </b-col>
                        </b-row>
                        <b-row>
                            <b-col cols="6" class="text-muted">
                                <div  class="m-1 pl-1">
                                    <div v-if="inventoryData.PropertyCreateTime">
                                        <span>
                                            <rt-icon icon="calendar-plus" variant="light"/>
                                        </span>
                                        <small>
                                            {{ i18nT(`Créé le`) }} {{ inventoryData.PropertyCreateTime }}
                                        </small>
                                    </div>
                                    <div v-if="inventoryData.PropertyLastEditDate">
                                        <span>
                                            <rt-icon icon="calendar-pen" variant="light"/>
                                        </span>
                                        <small>
                                            {{ i18nT(`Modifié le `) }} {{ inventoryData.PropertyLastEditDate }}
                                        </small>
                                    </div>
                                </div>
                            </b-col>
                            <b-col cols="6">
                                <div class="text-right m-1">
                                    <b-button
                                        variant="outline-secondary"
                                        class="mb-1 mr-1"
                                        :to="{ name: 'handovers'}"
                                    >
                                        {{i18nT(`Annuler`)}}
                                    </b-button>
                                    <b-button
                                        variant="primary"
                                        class="mb-1"
                                        @click.prevent="validationForm"
                                    >
                                        {{i18nT(`Sauvegarder`)}}
                                    </b-button>
                                </div>
                            </b-col>
                        </b-row>


                    </b-card>
                </div>
<!--                    ROOMS-->
                <div v-else>
                    <b-card>
                        <b-card-header class="p-0">
                            <h2>{{i18nT(`Pièce`)}}</h2>
                        </b-card-header>
                        <b-row class="mx-1 mb-2 pb-2">
                            <b-col cols="12" md="12">
                                <b-row class="mt-2">
                                    <b-col cols="12" md="6" class="d-flex">
                                        <b-form-group :label="i18nT(`Nom de la pièce`)">
                                            <b-form-input
                                                v-model="active.title"
                                            />
                                            <small class="ml-50">{{
                                                    i18nT(`Exemple: Salon, Cuisine, Chambre 1 ...`)
                                                }}</small>
                                        </b-form-group>
                                        <b-dropdown
                                            class="ren-dropdown"
                                            variant="link"
                                            toggle-class="p-0"
                                            no-caret
                                            dropright
                                        >
                                            <template #button-content >
                                                <b-avatar
                                                    class="badge-light-primary cursor-pointer ml-50 mb-50"
                                                    size="3rem"
                                                    v-b-tooltip.hover.top="i18nT(`Changer d'icône`)">
                                                    <RtIcon variant="light" :icon="active.icon ? active.icon : 'door-open'" :size="25"/>
                                                </b-avatar>
                                            </template>
                                            <div class="ren-icons-grid" v-if="!iconName">
                                                <b-dropdown-item
                                                    v-for="icon in gridIcons"
                                                    :key="icon.label"
                                                    @click="changeIcon(icon)"
                                                    v-b-tooltip.hover.top="i18nT(icon.label)"
                                                >
                                                            <span class="align-middle ml-50">
                                                                <rt-icon :icon="icon.icon"
                                                                         variant="light"
                                                                         :size="18"/>
                                                            </span>
                                                </b-dropdown-item>
                                            </div>
                                        </b-dropdown>
                                    </b-col>
                                </b-row>
                                <b-row>
                                    <b-col cols="12" md="6">
                                        <b-button variant="outline-secondary" @click="copyTab(active.id)" class="mr-1 mb-50">
                                            <rt-icon icon="copy" variant="light"/>
                                            {{ i18nT(`Dupliquer`) }}
                                        </b-button>
                                        <b-button variant="outline-danger" @click="removeTab(active.id)" class="mb-50">
                                            <rt-icon icon="trash" variant="light"/>
                                            {{ i18nT(`Supprimer`) }}
                                        </b-button>
                                    </b-col>
                                </b-row>
                                <b-row>
                                    <b-col cols="12" md="12" class="d-flex mt-2">
                                            <div class="ren-img">
                                                <b-img
                                                    src="@/assets/images/icons/room.png"
                                                    fluid
                                                />
                                            </div>
                                            <p class="ml-1 mt-1">{{
                                                    i18nT(`Pour chaque pièce et pour chaque élément d’équipement, préciser dans la case correspondante la nature, l’état d’usure et de fonctionnement. Exemple : «neuf», «bon état», «état moyen», «mauvais état», ou rayer la case si celle-ci est sans objet.`)
                                                }}</p>

                                    </b-col>
                                </b-row>
                            </b-col>
                        </b-row>
                        <b-row
                            class="border-bottom mb-2"
                            v-b-toggle='"walls"'
                            :class="isOpenWalls ? null : 'collapsed'"
                            :aria-expanded="isOpenWalls ? 'true' : 'false'"
                        >
                            <b-col
                                cols="12"
                            >
                                <div class="d-flex align-items-center justify-content-between">
                                    <h4 class="mb-2 mt-2">
                                        {{i18nT(`Murs, plafond, sol`)}}
                                    </h4>
                                    <rt-icon variant="light" :icon="isOpenWalls ? 'chevron-up mr-1' :  'chevron-down mr-1'"/>
                                </div>
                            </b-col>
                        </b-row>
                        <b-row class="mx-1">
                            <b-col cols="12" md="12">
                                <b-collapse
                                    :id="'walls'"
                                    v-model="isOpenWalls"
                                >
                                    <draggable v-model="active.newRoomFurnitureElement" tag="div"
                                                class="d-flex flex-column ren-drag-row">
                                        <b-row :class="{'mt-2': active.index === 0}"
                                                clas="border-bottom"
                                                v-for="(wall ,i) in active.newRoomFurnitureElement"
                                                :key="'key+' + i"
                                                :ref="'newRoomFurnitureElement' + i"
                                        >
                                            <b-col cols="12">
                                                <room-item-card 
                                                    :element="wall" 
                                                    :newFurnitureElement="true" 
                                                    :roomId="active.id" 
                                                    :itemIndex="i"
                                                    @delete="removeRoomItem"
                                                    @edit="editRoomItem"
                                                />
                                            </b-col>
                                        </b-row>
                                        <b-row
                                            class="pt-1 mb-1"
                                        >
                                            <b-col
                                                cols="12"
                                                md="9"
                                            >
                                                <b-button
                                                    @click="addRoomItem(active, 'newRoomFurnitureElement')"
                                                    variant="outline-primary"
                                                >
                                                    <rt-icon icon="circle-plus" variant="light"/>
                                                    {{ i18nT(` Ajouter un autre relevé eau, gaz`) }}
                                                </b-button>
                                            </b-col>
                                        </b-row>
                                    </draggable>
                                </b-collapse>
                            </b-col>
                        </b-row>
                        <b-row 
                            class="border-bottom mb-2" 
                            v-b-toggle='"equipments"'
                            :class="isOpenEquipment ? null : 'collapsed'"
                            :aria-expanded="isOpenEquipment ? 'true' : 'false'"
                        >
                            <b-col
                                cols="12"
                            >
                                <div class="d-flex align-items-center justify-content-between">
                                    <h4 class="mb-2 mt-2">
                                        {{i18nT(`Equipements`)}}
                                    </h4>
                                    <rt-icon variant="light" :icon="isOpenEquipment ? 'chevron-up mr-1' :  'chevron-down mr-1'"/>
                                </div>
                            </b-col>
                        </b-row>
                        <b-row class="mx-1">
                            <b-col cols="12" md="12">
                                <b-collapse
                                    :id="'equipments'"
                                    v-model="isOpenEquipment"
                                >
                                    <draggable v-model="active.newRoomFurniture" tag="div"
                                            class="d-flex flex-column ren-drag-row">
                                        <b-row :class="{'mt-3': active.index === 0}"
                                            v-for="(equipment ,i) in active.newRoomFurniture"
                                            :key="'key+' + i"
                                        >
                                            <b-col
                                                cols="12"
                                            >
                                                <room-item-card 
                                                    :element="equipment" 
                                                    :newFurniture="true" 
                                                    :roomId="active.id" 
                                                    :itemIndex="i"
                                                    @delete="removeRoomItem"
                                                    @edit="editRoomItem"
                                                />
                                            </b-col>
                                        </b-row>
                                        <b-row
                                            class="pt-1,mb-1"
                                        >
                                            <b-col
                                                cols="12"
                                                md="9"
                                            >
                                                <b-button
                                                    @click="addRoomItem(active, 'newRoomFurniture')"
                                                    variant="outline-primary"
                                                >
                                                    <rt-icon icon="circle-plus" variant="light"/>
                                                    {{ i18nT(` Ajouter un autre relevé eau, gaz`) }}
                                                </b-button>
                                            </b-col>
                                        </b-row>
                                    </draggable>
                                </b-collapse>
                            </b-col>
                        </b-row>
                        <b-row
                            class="border-bottom"
                            v-b-toggle='"comments"'
                            :class="isOpenComment ? null : 'collapsed'"
                            :aria-expanded="isOpenComment ? 'true' : 'false'"
                        >
                            <b-col
                                cols="12"
                            >
                                <div class="d-flex align-items-center justify-content-between">
                                    <h4 class="mb-2 mt-2">
                                        {{i18nT(`Commentaires`)}}
                                    </h4>
                                    <rt-icon variant="light" :icon="isOpenComment ? 'chevron-up mr-1' :  'chevron-down mr-1'"/>
                                </div>
                            </b-col>
                        </b-row>

                        <b-row class="mx-1 pb-2">
                            <b-col cols="12" md="12">
                                <b-collapse id="comments" v-model="isOpenComment">
                                    <b-row class="mt-3">
                                        <b-col cols="12">
                                            <b-form-group :label="i18nT(`Commentaires sur la pièce`)">
                                                <b-form-textarea
                                                    v-model="active.description"
                                                />

                                            </b-form-group>
                                        </b-col>
                                    </b-row>
                                </b-collapse>
                            </b-col>
                        </b-row>
                        <b-row
                            class="border-bottom"
                            v-b-toggle='"photos"'
                            :class="isOpenPhoto ? null : 'collapsed'"
                            :aria-expanded="isOpenPhoto ? 'true' : 'false'"
                        >
                            <b-col
                                cols="12"
                            >
                                <div class="d-flex align-items-center justify-content-between">
                                    <h4 class="mb-2 mt-2">
                                        {{i18nT(`Photos`)}}
                                    </h4>
                                    <rt-icon variant="light" :icon="isOpenPhoto ? 'chevron-up mr-1' :  'chevron-down mr-1'"/>
                                </div>
                            </b-col>
                        </b-row>
                        <b-row class="mx-1 mb-2 pb-2">
                            <b-col cols="12" md="12">
                                <b-collapse id="photos" v-model="isOpenPhoto">
                                    <b-row class="mt-3">
                                        <b-col cols="12">
                                            <vue-dropzone
                                                ref="roomPhotosDropZone"
                                                :duplicateCheck="true"
                                                @vdropzone-queue-complete="queCompleteMultiple"
                                                @vdropzone-error="dropzoneErrorMultiple"
                                                @vdropzone-success="dropzoneSuccessMultiple"
                                                id="dz-photo-multiple"
                                                class="ren-dropzone"
                                                :options="dropzoneOptions"
                                            />
                                            <p class="mt-1">
                                                {{ i18nT(`Formats acceptés: GIF, JPG, PNG. Taille maximale: 15 Mo`) }}</p>

                                        </b-col>
                                    </b-row>
                                </b-collapse>
                            </b-col>
                        </b-row>
                        <b-row>
                            <b-col cols="6" class="text-muted">
                                <div class="m-1 pl-1">
                                    <div v-if="inventoryData.PropertyCreateTime">
                                                <span>
                                                    <rt-icon icon="calendar-plus" variant="light"/>
                                                </span>
                                        <small>
                                            {{ i18nT(`Créé le`) }} {{ inventoryData.PropertyCreateTime }}
                                        </small>
                                    </div>
                                    <div v-if="inventoryData.PropertyLastEditDate">
                                                <span>
                                                    <rt-icon icon="calendar-pen" variant="light"/>
                                                </span>
                                        <small>
                                            {{ i18nT(`Modifié le `) }} {{ inventoryData.PropertyLastEditDate }}
                                        </small>
                                    </div>
                                </div>
                            </b-col>
                            <b-col cols="6">
                                <div class="text-right m-1">
                                    <b-button
                                        variant="outline-secondary"
                                        class="mb-1 mr-1"
                                        :to="{ name: 'handovers'}"
                                    >
                                        {{ i18nT(`Annuler`) }}
                                    </b-button>
                                    <b-button
                                        variant="primary"
                                        class="mb-1"
                                        @click.prevent="validationForm"
                                    >
                                        {{ i18nT(`Sauvegarder`) }}
                                    </b-button>
                                </div>
                            </b-col>
                        </b-row>

                    </b-card>
                </div>
            </b-col>

        </b-row>
        </validation-observer>

        <!-- MODAL ITEM ROOM -->

        <b-modal
            id="modal-add-room-item"
            :title="roomItem.type === 'newRoomFurnitureElement' ? i18nT(`Murs, plafond, sol`) : i18nT('Equipements')"
            :ok-title="i18nT(`Sauvegarder`)"
            :cancel-title="i18nT(`Annuler`)"
            cancel-variant="outline-secondary"
            no-close-on-backdrop
            @ok="saveRoomItem"
            @hidden="resetForm"
            size="lg"
        >
            <b-col cols="12">
                <b-row>
                    <b-col
                        cols="12"
                        :md="roomItem.type === 'newRoomFurnitureElement' ? '4' : '3'"
                        class="mt-1"
                    >
                        <b-form-group :label="i18nT(`Element`)">
                            <b-form-input
                                v-model="roomItem.Item"
                            />
                        </b-form-group>
                    </b-col>
                    <b-col
                        cols="12"
                        :md="roomItem.type === 'newRoomFurnitureElement' ? '4' : '3'"
                        class="mt-1"
                    >
                        <b-form-group :label="roomItem.type === 'newRoomFurnitureElement' ? i18nT(`Revêtement`) : i18nT(`Matériau`)">
                            <b-form-input
                                v-model="roomItem.Nature"
                            />
                        </b-form-group>
                    </b-col>
                    <b-col
                        cols="12"
                        :md="roomItem.type === 'newRoomFurnitureElement' ? '4' : '3'"
                        class="mt-1"
                    >
                        <b-form-group :label="i18nT(`Etat d'usure`)">
                            <v-select
                                v-model="roomItem.WearState"
                                :options="wearStateOptions"
                                label="text"
                                :clearable="false"
                                :reduce="(e) => +(e.value)"
                            />
                        </b-form-group>
                    </b-col>
                    <b-col
                        v-if="roomItem.type === 'newRoomFurniture'"
                        cols="12"
                        md="3"
                        class="mt-1"
                    >
                        <b-form-group :label="i18nT(`Fonctionnement`)">
                            <v-select
                                v-model="roomItem.Operation"
                                :options="operationOptions"
                                label="text"
                                :clearable="false"
                                :reduce="(e) => +(e.value)"
                            />
                        </b-form-group>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col
                        cols="12"
                        md="12"
                        class="mt-1"
                    >
                        <b-form-group :label="i18nT(`Commentaire`)">
                            <b-form-textarea
                                id="comment"
                                v-model="roomItem.Comments"
                            />
                        </b-form-group>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col
                        cols="12"
                        class="d-flex justify-content-between"
                    >
                        <div>
                    <span class="pb-0 mb-0" v-if="roomItem.photoURL">
                    <b-link :href="roomItem.photoURL" target="_blank">
                        <b-img
                            :src="roomItem.photoURL"
                            thumbnail
                            alt="room-phoro"
                            class="mr-1 ren-identity-photo"
                            width="200"
                            height="200"
                        />
                    </b-link>
                    <b-button
                        variant="outline-danger"
                        size="sm"
                        class="cursor-pointer"
                        @click="deletePhoto"
                    >
                        <rt-icon icon="trash" variant="light" :size="16"/>
                        </b-button>
                        </span>
                            <!--                      TODO add methods for file upload-->
                            <b-button
                                class="mt-0 mr-1 mb-2"
                                variant="outline-primary"
                                @click="onFilePick"
                                v-if="!roomItem.photoURL"
                            >
                                <rt-icon icon="plus-circle" variant="light" :size="14"/>
                                {{ i18nT(`Parcourir`) }}
                            </b-button>

                            <b-form-file
                                :ref="'roomPhoto'"
                                v-model="photo"
                                class="hidden"
                                @input="addPhoto"
                            />

                        </div>
                        <div v-if="roomItem.index !== null">
                            <b-button
                                variant="outline-danger"
                                @click="removeRoomItem(roomItem.roomId, roomItem.type, roomItem.index)"
                            >
                                {{ i18nT(`Supprimer l'élément`) }}
                            </b-button>
                        </div>
                    </b-col>
                </b-row>
            </b-col>
        </b-modal>

        <modal-room-general-info :element="genInfoElement" :elementType="genInfoType" @resetForm="resetGenInfoModal" @delete="removeGeneralItem" @save="saveGeneralItem"/>

    </div>
</template>


<script>

import {
    BTabs,
    BTab,
    BNavItem,
    BCard,
    BFormInput,
    BRow,
    BCol,
    BFormGroup,
    BButton,
    BCollapse,
    BDropdownItem,
    BDropdown,
    VBTooltip,
} from 'bootstrap-vue'
import EllipsisDropdown from '@core/components/EllipsisDropdown.vue'
import draggable from 'vuedraggable'
import RtIcon from "@core/components/rt-icon/RtIcon.vue";
import {ValidationProvider, ValidationObserver} from "vee-validate";
import { required} from '@validations'
import router from "@/router";
import vSelect from "vue-select";
import DatePicker from "@core/components/DatePicker.vue";
import vue2Dropzone from 'vue2-dropzone';
import {API_KEY} from "@core/utils/constants";
import useAuth from "@/auth/useAuth";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import 'vue2-dropzone/dist/vue2Dropzone.min.css';
import RoomItemCard from '@/views/components/roomItemCard/RoomItemCard.vue';
import RoomGeneralInfoCard from '@/views/components/roomGeneralInfoCard/RoomGeneralInfoCard.vue';
import ModalRoomGeneralInfo from '@/views/components/modalRoomGeneralInfo/ModalRoomGeneralInfo.vue'
import samplePhoto from "@/assets/images/pages/content-img-4.jpg";
import RtSelect from "@core/components/RtSelect.vue";



export default{
    components: {
        RtSelect,
        BDropdown,
        BDropdownItem,
        DatePicker,
        BButton,
        ValidationProvider,
        ValidationObserver,
        BFormGroup,
        BCol,
        BRow,
        BFormInput,
        BTabs,
        BTab,
        BNavItem,
        BCollapse,
        draggable,
        RtIcon,
        BCard,
        EllipsisDropdown,
        vSelect,
        VueDropzone: vue2Dropzone,
        ToastificationContent,
        RoomItemCard,
        RoomGeneralInfoCard,
        ModalRoomGeneralInfo,
    },
    directives: {
        'b-tooltip': VBTooltip
    },

    data(){
        return {
            required,
            iconName:'',
            active: 'general-info',
            genInfoElement: {},
            genInfoType: '',
            tabs: this.createdTabs(),
            roomItem: {},
            photo: null,
            leaseAndReservation: '',
            isOpenRoomsTab: false,
            isOpenWalls: false,
            isOpenEquipment: false,
            isOpenComment: false,
            isOpenPhoto: false,
            inventoryData: {},
            inventoryTypes: [
                {value: 'exit', text: "État des lieux d'entrée"},
                {value: 'enter', text: "État des lieux de sortie"},
            ],
            leasesAndReservations: [],
            swm: [{type: 'swm', Type: 'Test 1', SerialNo: 'test', M3: 'test', Operation: 1, Comments: 'Lorem ipsum dolor sit amet, pro justo decore utamur at, putant sententiae pri ut. Viris sensibus ea vix. Quando facilis vix et, ius eu augue tempor.'},],
            emr: [{type: 'emr', Type: 'Test 2', SerialNo: 'test', M3: 'test', Operation: 1, Comments: 'Lorem ipsum dolor sit amet, pro justo decore utamur at, putant sententiae pri ut. Viris sensibus ea vix. Quando facilis vix et, ius eu augue tempor.'},            ],
            th: [{type: 'th', Type: 'Test 3', SerialNo: 'test', M3: 'test', Operation: 1, Comments: 'Lorem ipsum dolor sit amet, pro justo decore utamur at, putant sententiae pri ut. Viris sensibus ea vix. Quando facilis vix et, ius eu augue tempor.'},],
            hw: [
                {type: 'hw', Type: 'Test 4', WearState: 4, Operation: 1, Comments: 'Lorem ipsum dolor sit amet, pro justo decore utamur at, putant sententiae pri ut. Viris sensibus ea vix. Quando facilis vix et, ius eu augue tempor.'},
            ],
            key:[{type: 'key', Type: 'Test 5', Count: 'test', Given: 'test', Date: '2024-06-26', Comments: 'Lorem ipsum dolor sit amet, pro justo decore utamur at, putant sententiae pri ut. Viris sensibus ea vix. Quando facilis vix et, ius eu augue tempor.'},],
            oth:[{type: 'oth', Type: 'Test 6', SerialNo: 'test', WearState: 2, Operation: 1, Comments: 'Lorem ipsum dolor sit amet, pro justo decore utamur at, putant sententiae pri ut. Viris sensibus ea vix. Quando facilis vix et, ius eu augue tempor.'}],
            operationOptions: [
                {value: 0, text: "Non vérifié"},
                {value: 1, text: "Fonctionne"},
                {value: 2, text: "Hors service"},
            ],
            wearStateOptions: [
                {value: 0, text: "Non vérifié"},
                {value: 1, text: "Neuf"},
                {value: 2, text: "Bon état"},
                {value: 3, text: "Etat moyen"},
                {value: 4, text: "Mauvais état"},
            ],
            dropzoneOptions: {
                // TODO add correct url once it's available //
                url: process.env.VUE_APP_API_URL+'invoices/invoiceAttachments',
                addRemoveLinks: true,
                autoProcessQueue: true,
                clickable: true,
                dictDefaultMessage: this.i18nT(`Déposez des fichiers ici ou cliquez pour charger.`),
                dictRemoveFile: this.i18nT(`Retirer`),
                dictFileTooBig: this.i18nT(`Le fichier est trop volumineux ({{filesize}} Mo). La taille maximale du fichier est : {{maxFilesize}} Mo`),
                dictInvalidFileType: this.i18nT(`Seuls les PDF et JPG sont autorisés`),
                dictCancelUpload: this.i18nT(`Annuler`),
                dictCancelUploadConfirmation: this.i18nT(`Êtes-vous sûr de vouloir annuler ce téléchargement?`),
                dictMaxFilesExceeded: this.i18nT(`Vous pouvez joindre jusqu'à 10 fichiers`),
                maxFilesize: 5,
                maxFiles: 10,
                acceptedFiles: 'image/*',
                headers: {
                    'Api-Key': API_KEY,
                    'Auth-Key': useAuth.getToken(),
                },
            },
            count: 0,
            tabNames: [],
            defaultIcons: ['door-open','couch','oven','bed','bath','toilet-paper'],
            notes: "",
            gridIcons: [
                { icon: 'door-open', label: 'Entree' },
                { icon: 'couch', label: 'Séjour' },
                { icon: 'oven', label: 'Cuisine' },
                { icon: 'bed', label: 'Chambre' },
                { icon: 'bath', label: 'Salle de bain' },
                { icon: 'toilet-paper', label: 'WC' },
                { icon: 'tv', label: 'TV' },
                { icon: 'key', label: 'Clé' },
                { icon: 'phone-office', label: 'Bureau' },
                { icon: 'garage', label: 'Garage' },
                { icon: 'teddy-bear', label: "Chambre d'enfant" },
                { icon: 'tree-deciduous', label: 'Arrière-cour' },
            ],
            curInventoryId: router.currentRoute.params.id,
        }
    },
    created(){
        this.count = this.tabs.length + 1;
        
        this.defaultIcons.forEach((icon, index) => {
            this.tabs[index].icon = icon
        })

        this.$http.get(`landlord/handovers/new`).then(({data})=>{
            let leaseRecords = data.data['lease_records'].map(lease => {
                const startDate = lease.LeaseStartDate ? lease.LeaseStartDate : '';
                const endDate = lease.LeaseEndDate != '0000-00-00' ? lease.LeaseEndDate : '';
                let tenantName = '';
                if(lease.Tenants.length > 0){
                    lease.Tenants.map( (tenant, index) => {
                        tenantName += index > 0 ? ", " : ""
                        tenantName += tenant.TenantFullName
                    })
                }
               return {
                   value: +lease.LeaseID,
                   text: `${lease.PropertyTitle} - ${tenantName} ( ${endDate ? `${startDate} - ${endDate}` : `${startDate}`} )`,
                   isLease: true
               }
            })
            let leaseArchived = data.data['lease_archived_records'].map(lease => {
                const startDate = lease.LeaseStartDate ? lease.LeaseStartDate : '';
                const endDate = lease.LeaseEndDate != '0000-00-00' ? lease.LeaseEndDate : '';
                let tenantName = '';
                if(lease.Tenants.length > 0){
                    lease.Tenants.map( (tenant, index) => {
                        tenantName += index > 0 ? ", " : ""
                        tenantName += tenant.TenantFullName
                    })
                }
               return {
                   value: +lease.LeaseID,
                   text: `${lease.PropertyTitle} - ${tenantName} ( ${endDate ? `${startDate} - ${endDate}` : `${startDate}`} )`,
                   isLease: true
               }
            })
            let reservationRecords = data.data['reservation_records'].map(reservation => {
                const startDate = reservation.ReservationStartDate ? reservation.ReservationStartDate : '';
                const endDate = reservation.ReservationEndDate != '0000-00-00' ? reservation.ReservationEndDate : '';
                let tenantName = '';
                if(reservation.Tenants.length > 0){
                    reservation.Tenants.map( (tenant, index) => {
                        tenantName += index > 0 ? ", " : ""
                        tenantName += tenant.TenantFullName
                    })
                }
               return {
                   value: +reservation.ReservationID,
                   text: `${reservation.PropertyTitle} - ${tenantName} ( ${endDate ? `${startDate} - ${endDate}` : `${startDate}`} )`,
                   isLease: false
               }
            })

            if(leaseRecords.length > 0) {

                leaseRecords.unshift({
                    value: -1,
                    text: this.i18nT(  `-- LOCATIONS ACTIVE --`),
                    disabled: true,
                });
            }

            if(leaseArchived.length > 0) {

                leaseArchived.unshift({
                    value: -1,
                    text: this.i18nT(  `-- LOCATIONS ARCHIVES --`),
                    disabled: true
                });
            }

            if(reservationRecords.length > 0) {
                reservationRecords.unshift({
                    value: -1,
                    text: this.i18nT(  `-- RÉSERVATIONS --`),
                    disabled: true
                });
            }


            this.leasesAndReservations = [...leaseRecords, ...leaseArchived, ...reservationRecords]

            this.leasesAndReservations.unshift({
                value: 0,
                text: this.i18nT(`Pas lié à une location ou une réservation`)
            })
        })

        // Fetching existing handover data
        if(this.curInventoryId) {
            this.$http.get(`landlord/handovers?id=${this.curInventoryId}&edit_data=1`)
            .then(({data}) => {
                console.log("EditInventory", data)
                this.inventoryData = data.data.handover
                this.leaseAndReservation = data.data.handover.LeaseID
                this.tabs = data.data['handover_rooms'].map(room => {
                    return {
                        title: room.LeaseHandoverName,
                        newRoomFurnitureElement: room.FurnitureElements,
                        newRoomFurniture: room.Furniture,
                        description: room.LeaseHandoverNote,
                        id: +room.LeaseHandoverRoomID,
                        icon: 'door-open'
                    }
                })
            })
        }
    },
    methods: {
        onDelete() {
            this.$swal({
                title: this.i18nT(`Avertissement`),
                text: this.i18nT(`Veuillez confirmer la suppression du locataire.`),
                icon: 'warning',
                showCancelButton: true,
                cancelButtonText: this.i18nT(`Annuler`),
                confirmButtonText: this.i18nT(`Confirmer`),
                customClass: {
                    confirmButton: 'btn btn-primary',
                    cancelButton: 'btn btn-outline-danger ml-1'
                },
                buttonsStyling: false
            }).then(result => {
                if (result.value) {
                    this.$http
                        .delete(`landlord/handovers?id=${this.curInventoryId}`)
                        .then(() => {
                            this.$swal({
                                icon: 'success',
                                title: this.i18nT(`Succès!`),
                                text: this.i18nT(`Supprimé avec succès!`),
                                customClass: {
                                    confirmButton: 'btn btn-success'
                                }
                            })
                            this.$router.push({ name: 'handovers' })
                        })
                }
            }).catch(err => {
                this.$swal({
                    icon: 'error',
                    title: 'Error!',
                    text: this.errorMessage(err),
                    customClass: {
                        confirmButton: 'btn btn-success'
                    }
                })
            })
        },
        changeTab(index) {
            if(typeof(index) === "string") {
                this.active = index;
            } else {
                this.active = this.tabs[index]
            }
        },
        createdTabs(){

            //TODO check localization might need refactoring
            const titles = [this.i18nT(`Entree`), this.i18nT(`Sejour`), this.i18nT(`Cuisine`), this.i18nT(`Chambre 1`), this.i18nT(`Salle de bain 1`), this.i18nT(`WC 1`)];
            const items = ['Murs A', 'Murs B', 'Murs C', 'Murs D', 'Sol', 'Plafond', 'Porte paliere', 'Plinthes'];
            const newTabs = [];
            //TODO change id when endpoint is ready
            let id = 1;

            titles.forEach(title=> {
                const newRoomFurnitureElement = []
                const newRoomFurniture = []
                items.forEach(item => {
                    newRoomFurnitureElement.push({Item: item, WearState: 1});
                    newRoomFurniture.push({Item: item, WearState: 1, Operation: 1,});
                });
                const icon = 'door-open';
                newTabs.push({title, newRoomFurnitureElement, newRoomFurniture, icon, id: `n${id}`});
                id++ ;

            });
            console.log("tabs: ", newTabs)

            //TODO remove - this is just for testing purposes

            newTabs[0].newRoomFurnitureElement[0].Nature= 1231;
            newTabs[0].newRoomFurnitureElement[0].photoURL= samplePhoto;
            newTabs[0].newRoomFurnitureElement[0].Comments = "Lorem ipsum dolor sit amet, pro justo decore utamur at, putant sententiae pri ut. Viris sensibus ea vix. Quando facilis vix et, ius eu augue tempor.";
            newTabs[0].newRoomFurniture[0].Comments = "Lorem ipsum dolor sit amet, pro justo decore utamur at, putant sententiae pri ut. Viris sensibus ea vix. Quando facilis vix et, ius eu augue tempor.";
            newTabs[0].newRoomFurniture[0].Nature= 1231;
            newTabs[0].newRoomFurniture[0].photoURL= samplePhoto;

            return newTabs;
        },
        editGeneralInfo(event, index, type) {
            this.genInfoElement = event
            this.genInfoElement.index = index
            this.genInfoType = type
            this.$bvModal.show('modal-add-general-room-info')
            
        },
        resetGenInfoModal() {
            this.genInfoElement = {}
            this.genInfoType = ''
        },
        removeGeneralItem(index, type){
            this[type].splice(index, 1)
            this.$bvModal.hide('modal-add-general-room-info')
        },
        openGeneralItemModal(type){
            this.genInfoElement = {}
            this.genInfoType = type
            this.$bvModal.show('modal-add-general-room-info')
        },
        saveGeneralItem(element, type) {
            if(element.index !== undefined) {
                this[type].splice(element.index, 1, {...element, type: type})
            } else {
                this[type].push({...element, type: type})
            }
        },
        changeIcon(icon){
            const index = this.tabs.findIndex(tab => tab.id === this.active.id)
            this.tabs[index].icon = icon.icon;
        },
        addTab(){
            const items = ['Murs A', 'Murs B', 'Murs C', 'Murs D', 'Sol', 'Plafond', 'Porte paliere', 'Plinthes'];

            this.tabs.push({
                id: this.count,
                title: `Pièce ${this.count++}`,
                newRoomFurnitureElement: Array.from({length: 8}, (val, index) => ({Item: items[index], WearState:1, photoFile:null})),
                newRoomFurniture: Array.from({length: 8}, (val, index) => ({Item: items[index], WearState: 1, Operation: 1, photoFile:null})),
                icon: 'door-open'
            })
        },
        removeTab(id){
            const index = this.tabs.findIndex(tab => tab.id === id)
            this.tabs.splice(index, 1)

            if(this.tabs.length > 0) {
                this.active = this.tabs[index] ? this.tabs[index] : this.tabs[index - 1]
            }
            else {
                this.active = 'general-info'
            }
        },
        copyTab(id){
            const index = this.tabs.findIndex(tab => tab.id === id)

            const newTab = JSON.parse(JSON.stringify(this.tabs[index]))
            newTab.title = `${this.tabs[index].title}(Copie)`
            newTab.id = `n${this.count}`

            this.tabs.push(newTab)

            this.count++;
        },
        editRoomItem(item, id, type, index) {

            this.roomItem = {
                ...item,
                roomId: id,
                type: type,
                WearState: item.WearState !== undefined ? +item.WearState : null,
                Operation: item.Operation !== undefined ? +item.Operation : null,
                index: index
            }

            this.$bvModal.show('modal-add-room-item')
        },
        removeRoomItem(id, content, index) {
            const i = this.tabs.findIndex(tab => tab.id === id)

            this.tabs[i][content].splice(index, 1)
            this.$bvModal.hide('modal-add-room-item');
        },
        saveRoomItem() {
            const index = this.tabs.findIndex(tab => tab.id === this.roomItem.roomId)

            if(this.roomItem.index !== null) {
                this.tabs[index][this.roomItem.type].splice(this.roomItem.index, 1, this.roomItem)
            } else {
                this.tabs[index][this.roomItem.type].push(this.roomItem)
            }
        },
        resetForm() {
            this.roomItem = {}
        },
        addRoomItem(item, type) {
            this.roomItem = {
                roomId: item.id,
                type: type,
                index: null
            }

            this.$bvModal.show('modal-add-room-item')
        },
        queCompleteMultiple() {
            let self = this;

            setTimeout(function () {
                self.$refs.roomPhotosDropzone.removeAllFiles();
            }, 4000);
        },
        dropzoneErrorMultiple(message){
            console.log("message: ", message)
            this.$toast({
                component: ToastificationContent,
                position: 'top-right',
                timeout: 9000,
                props: {
                    title: this.i18nT(message.previewElement.childNodes[7].innerText),
                    text: message.upload.filename,
                    icon: 'InfoIcon',
                    variant: 'danger',
                    timeout: 20000,
                },
            })
        },
        dropzoneSuccessMultiple: function (files, response) {
            // TODO add to photos when api is ready
            // this.document.InvoiceDocuments.push({...response.data, AttachedFile: {DownloadUrl: response.data.DownloadUrl}});

        },
        onFilePick() {
            this.$refs['roomPhoto'].$refs.input.click()
        },
        addPhoto(){
            const url = URL.createObjectURL(this.photo);
            this.roomItem.photoFile = this.photo;
            this.roomItem.photoURL = url;
        },
        deletePhoto(){
            this.roomItem.photoFile = null;
            this.roomItem.photoURL = null;
            this.roomItem = {...this.roomItem}
        },
        validationForm() {
            this.$refs.simpleRules.validateWithInfo().then(({isValid, errors, fields}) => {
                if(isValid){
                    const formData = new FormData()

                    //GENERAL INFO

                    if(this.inventoryData.LeaseHandoverID) {
                        formData.append('LeaseHandoverID', this.inventoryData.LeaseHandoverID)
                    }
                    formData.append('HandoverType', this.inventoryData.HandoverType);
                    formData.append('Name', this.inventoryData.Name);

                    if(this.leaseAndReservation) {
                        //TODO check if this is correct
                        this.leasesAndReservations.find(lease => (lease.value === this.leaseAndReservation && lease.isLease)) ?
                            formData.append('LeaseID', this.leaseAndReservation) :
                            formData.append('ReservationID', this.leaseAndReservation)
                    }
                    formData.append('LeaseHandoverObservations', this.notes)

                    //Meter readings (water, gas, etc.) data

                    this.swm.forEach((item, index) => {
                        formData.append(`swm[${index}][Type]`, item.Type)
                        formData.append(`swm[${index}][SerialNo]`, item.SerialNo)
                        formData.append(`swm[${index}][M3]`, item.M3)
                        formData.append(`swm[${index}][Operation]`, item.Operation)
                        formData.append(`swm[${index}][Comments]`, item.Comments)
                    })

                    //Electricity meter readings data

                    this.emr.forEach((item, index) => {
                        formData.append(`emr[${index}][Type]`, item.Type)
                        formData.append(`emr[${index}][SerialNo]`, item.SerialNo)
                        formData.append(`emr[${index}][M3]`, item.M3)
                        formData.append(`emr[${index}][Operation]`, item.Operation)
                        formData.append(`emr[${index}][Comments]`, item.Comments)
                    })

                    //Heating system data

                    this.th.forEach((item, index) => {
                        formData.append(`th[${index}][Type]`, item.Type);
                        formData.append(`th[${index}][SerialNo]`, item.SerialNo);
                        formData.append(`th[${index}][M3]`, item.M3);
                        formData.append(`th[${index}][Operation]`, item.Operation);
                        formData.append(`th[${index}][Comments]`, item.Comments);
                    })

                    //Water heating data

                    this.hw.forEach((item, index) => {
                        formData.append(`hw[${index}][Type]`, item.Type);
                        formData.append(`hw[${index}][WearState]`, item.WearState);
                        formData.append(`hw[${index}][Operation]`, item.Operation);
                        formData.append(`hw[${index}][Comments]`, item.Comments);
                    });

                    //Keys data

                    this.key.forEach((item, index) => {
                        formData.append(`key[${index}][Type]`, item.Type);
                        formData.append(`key[${index}][Count]`, item.Count);
                        formData.append(`key[${index}][Given]`, item.Given);
                        formData.append(`key[${index}][Date]`, item.Date ? item.Date : null);
                        formData.append(`key[${index}][Comments]`, item.Comments);
                    });

                    //Other items data

                    this.oth.forEach((item, index) => {
                        formData.append(`oth[${index}][Type]`, item.Type);
                        formData.append(`oth[${index}][SerialNo]`, item.SerialNo);
                        formData.append(`oth[${index}][WearState]`, item.WearState);
                        formData.append(`oth[${index}][Operation]`, item.Operation);
                        formData.append(`oth[${index}][Comments]`, item.Comments);
                    });

                    //ROOMS DATA
                    for (let i = 0; i < this.tabs.length; i++) {
                        console.log(this.tabs[i])
                        if(typeof(this.tabs[i].id) === 'number') {
                            formData.append(`room[${i}][LeaseHandoverRoomID]`, this.tabs[i].id)
                        }
                        formData.append(`room[${i}][title]`, this.tabs[i].title)
                        formData.append(`room[${i}][icon]`, this.tabs[i].icon)

                        for(let j = 0; j < this.tabs[i].newRoomFurnitureElement.length; j++) {
                            formData.append(`room[${i}][newRoomFurnitureElement][${j}][Item]`, this.tabs[i].newRoomFurnitureElement[j].Item)
                            formData.append(`room[${i}][newRoomFurnitureElement][${j}][Nature]`, this.tabs[i].newRoomFurnitureElement[j].Nature)
                            formData.append(`room[${i}][newRoomFurnitureElement][${j}][WearState]`, this.tabs[i].newRoomFurnitureElement[j].WearState)
                            formData.append(`room[${i}][newRoomFurnitureElement][${j}][Comments]`, this.tabs[i].newRoomFurnitureElement[j].Comments)
                        }


                        for(let j = 0; j < this.tabs[i].newRoomFurniture.length; j++) {
                            formData.append(`room[${i}][newRoomFurniture][${j}][Item]`, this.tabs[i].newRoomFurniture[j].Item)
                            formData.append(`room[${i}][newRoomFurniture][${j}][SerialNo]`, this.tabs[i].newRoomFurniture[j].SerialNo)
                            formData.append(`room[${i}][newRoomFurniture][${j}][Operation]`, this.tabs[i].newRoomFurniture[j].Operation)
                            formData.append(`room[${i}][newRoomFurniture][${j}][WearState]`, this.tabs[i].newRoomFurniture[j].WearState)
                            formData.append(`room[${i}][newRoomFurniture][${j}][Comments]`, this.tabs[i].newRoomFurniture[j].Comments)
                        }
                    }


                    this.$http.post('axl', formData)
                } else {
                    let y = 1000000
                    let prevY = 1000000
                    for (const key in errors) {
                        if (errors[key].length > 0) {
                            this.$toast({
                                component: ToastificationContent,
                                position: 'top-right',
                                props: {
                                    title: errors[key][0],
                                    icon: 'AlertTriangleIcon',
                                    variant: 'danger'
                                }
                            })
                            // console.log(fields[key])
                            const el = document.getElementById(fields[key].id)
                            prevY =
                                el.getBoundingClientRect().top + window.scrollY - 150
                            if (prevY <= y) y = prevY
                        }
                    }
                    if (y < 1000000) {
                        window.scrollTo({ top: y, behavior: 'smooth' })
                    }
                }

            })
        }
    }
}
</script>

<style land="scss">
/* @import '@client/airfit/client_variables.scss'; */


.dark-layout .navbar-custom{
    background-color: #161d31 !important;
}

.ren-add-tab-button:hover {
    opacity: 1;
    transform: translateY(-2.5px);
    color: white;
}
.ren-add-tab-button {
    transition: transform 0.3s ease-in-out;
    opacity: .7;
    display: inline-block;
    margin-bottom: 0;
    font-size: 14px;
    font-weight: 400;
    line-height: 1.42857143;
    cursor: pointer;
    border: 1px solid transparent;
    border-radius: 4px;
    min-width: 50%;
    padding: 4px;
    background-color: var(--primary);
    /* box-shadow: 0 4px 18px -4px  rgba($primary, 0.7); */
    /* background-image: linear-gradient(118deg, rgb(114, 163, 51), rgba(114, 163, 51, 0.7)); */

}

.ren-img {
    width: 100%;
    max-height: 50px;
    max-width: 80px;
}

.ren-drag-row {
    cursor: grab;
}

.ren-room-tab{
    display: flex;
    flex-direction: column;
    align-items: start;
}

.rt-rooms-tab {
    background-color: #f0f0f0 !important;
    border-radius: 5px;
}

.dark-layout .rt-rooms-tab {
    background-color: #1a2338 !important;
}

/* .b-nav-pills-custom {
    border-radius: 0.358rem;
    color: var(--primary);
    padding: 4px;
    border: 1px solid transparent;
    font-size: 1rem;
    width: 100% !important;
    display: inline-block;
    margin-bottom: 5px;
} */

/* .b-nav-pills-custom div {
    transition: transform 0.3s ease-in-out;
}

.b-nav-pills-custom:hover div {
    transform: translateX(7px);
} */

/* .b-nav-pills-custom-active {
    background-color: var(--primary);
    box-shadow: 0 4px 18px -4px var(--primary);
    padding: 4px;
} */

.ren-icons-grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr); /* Adjust the number as per your requirement */
    grid-gap: 10px; /* Optional: adds some space between the items */
}

 .ren-dropdown .dropdown-menu {
     min-width: 200px;
     margin-top: 0px;
 }

.nav-item > a {
    color: inherit;
}

.nav-item > a:hover {
    color: inherit;
}




</style>
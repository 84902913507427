<template>
    <b-card no-body border-variant="primary">
        <b-card-body class="py-1">
            <div
                class="d-lg-flex align-items-center"
            >
                <div class="flex-grow-1">
                    <h4 class="cursor-pointer">{{ element.Type }}</h4>
                    <div class="d-lg-flex flex-column">
                        <span v-for="(key, index) in elementKeys" :key="'element' + index">
                            <p v-if="element[key]" class="mr-1 mb-0" >
                                <strong>{{ elementLabels[key] }}:</strong> {{ key === 'Operation' ?  operationText : (key === 'WearState' ? wearStateText : element[key])}}
                            </p>
                        </span>
                    </div>
                    <p v-if="element.Comments" class="mb-0 mt-50">
                        {{ element.Comments }}
                    </p>
                </div>
                <div class="float-right">
                    <b-button-group
                        size="sm"
                    >
                        <b-button
                            variant="outline-primary"
                            @click="onEdit"
                        >
                            <rt-icon icon="edit" variant="light" :size="16"/>
                        </b-button>
                        <b-button
                            variant="outline-primary"
                            @click="onDelete"
                        >
                            <rt-icon icon="trash" variant="light" :size="16"/>
                        </b-button>
                    </b-button-group>
                </div>
            </div>
        </b-card-body>
    </b-card>

</template>


<script>

import {
    BCard,
    BCardBody,
    BCol,
    BRow,
    BAvatar,
} from "bootstrap-vue";


export default {
    components: {
        BCardBody,
        BRow,
        BCard,
        BCol,
        BAvatar,
        },
    props: {
        element: {
            type: Object,
            default: null,
        },
        elementType: {
            type: String,
            default: '',
        },
    },
    data(){
        return {
            operationOptions: [
                {value: 0, text: "Non vérifié"},
                {value: 1, text: "Fonctionne"},
                {value: 2, text: "Hors service"},
            ],
            wearStateOptions: [
                {value: 0, text: "Non vérifié"},
                {value: 1, text: "Neuf"},
                {value: 2, text: "Bon état"},
                {value: 3, text: "Etat moyen"},
                {value: 4, text: "Mauvais état"},
            ],
            operationText: null,
            wearStateText: null,
            elementKeys: [],
            elementLabels: {}
        };
    },
    methods: {
        onEdit() {
            this.$emit('edit', this.element, this.elementType)
        }, 
        onDelete() {
            this.$emit('delete', this.element.index, this.elementType)
        }
    },
    watch: {
        element: {
            handler: function (newVal) {
                if(newVal.Operation !== undefined){
                    const operation = this.operationOptions.find(option => option.value === newVal.Operation);
                    this.operationText = operation ? operation.text : null;
                }

                if(newVal.WearState !== undefined) {
                    const wearState = this.wearStateOptions.find(option => option.value === newVal.WearState);
                    this.wearStateText = wearState ? wearState.text : null;
                }
            },
            immediate: true
        },
        elementType: {
            handler: function (val) {
                if (val) {
                    switch (val) {
                        case "swm":
                            this.elementKeys = ['SerialNo', 'M3', 'Operation'];
                            this.elementLabels = {SerialNo: this.i18nT(`No. de serie`), M3: this.i18nT(`M3`), Operation: this.i18nT(`Fonctionnement`)}
                            break;
                        case "emr":
                            this.elementKeys = ['SerialNo', 'M3', 'Operation'];
                            this.elementLabels = {SerialNo: this.i18nT(`No. de serie`), M3: this.i18nT(`KWH`), Operation: this.i18nT(`Fonctionnement`)}
                            break;
                        case "th":
                            this.elementKeys = ['SerialNo', 'M3', 'Operation'];
                            this.elementLabels = {SerialNo: this.i18nT(`No. de serie`), M3: this.i18nT(`M3/KWH`), Operation: this.i18nT(`Fonctionnement`)}
                            break;
                        case "hw":
                            this.elementKeys = ['WearState', 'Operation'];
                            this.elementLabels = {WearState: this.i18nT(`Etat d’usure`), Operation: this.i18nT(`Fonctionnement`)}

                            break;
                        case "key":
                            this.elementKeys = ['Count', 'Given', 'Date'];
                            this.elementLabels = {Count: this.i18nT(`Nombre`), Given: this.i18nT(`Remis`), Date: this.i18nT(`Date`)}
                            break;
                        case "oth":
                            this.elementKeys = ['SerialNo', 'WearState', 'Operation'];
                            this.elementLabels = {SerialNo: this.i18nT(`Matériau`), WearState: this.i18nT(`Etat d’usure`), Operation: 'Fonctionnement'}
                            break;
                        default:
                            this.elementKeys = [];
                            break;
                    }
                }
            },
            immediate: true
        }
    }
}
</script>

<style>
</style>
<template>
    <div>
        <b-modal
            id="modal-add-general-room-info"
            :title="elementTitle"
            :ok-title="i18nT(`Sauvegarder`)"
            :cancel-title="i18nT(`Annuler`)"
            cancel-variant="outline-secondary"
            no-close-on-backdrop
            @ok="saveElement"
            @hidden="resetForm"
            size="lg"
        >
            <b-col cols="12">
                <b-row>
                    <b-col
                        cols="12"
                        :md="elementType === 'hw' ? '4' : '3'"
                        class="mt-1"
                    >
                        <b-form-group :label=elementLabels.Type>
                            <b-form-input
                                v-model="value.Type"
                            />
                        </b-form-group>
                    </b-col>
                    <b-col
                        cols="12"
                        :md="elementType === 'hw' ? '4' : '3'"
                        class="mt-1"
                        v-for="(key, index) in elementKeys"
                        :key="'input' + index"
                    >
                        <b-form-group :label="elementLabels[key]">
                            <v-select
                                v-if="key === 'WearState'"
                                v-model="value[key]"
                                :options="wearStateOptions"
                                label="text"
                                :clearable="false"
                                :reduce="(e) => +(e.value)"
                            />

                            <v-select
                                v-else-if="key === 'Operation'"
                                v-model="value[key]"
                                :options="operationOptions"
                                label="text"
                                :clearable="false"
                                :reduce="(e) => +(e.value)"
                            />

                            <date-picker
                                v-else-if="key === 'Date'"
                                v-model="value[key]"
                                reset-button
                                :manual-input="true"
                            />

                            <b-form-input
                                v-else
                                v-model="value[key]"
                            />
                        </b-form-group>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col
                        cols="12"
                        md="12"
                        class="mt-1"
                    >
                        <b-form-group :label="i18nT(`Commentaire`)">
                            <b-form-textarea
                                id="comment"
                                v-model="value.Comments"
                            />
                        </b-form-group>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col
                        cols="12"
                        class="d-flex justify-content-between"
                    >
                        <div v-if="value.index !== undefined">
                            <b-button
                                variant="outline-danger"
                                @click="deleteElement"
                            >
                                {{ i18nT(`Supprimer l'élément`) }}
                            </b-button>
                        </div>
                    </b-col>
                </b-row>
            </b-col>
        </b-modal>
    </div>
</template>

<script>

import {
    BCard,
    BCardBody,
    BCol,
    BRow,
    BAvatar,
} from "bootstrap-vue";
import DatePicker from "@core/components/DatePicker.vue";
import vSelect from "vue-select";

export default {
    components: {
        BCardBody,
        BRow,
        BCard,
        BCol,
        BAvatar,
        DatePicker,
        vSelect,
        },
    props: {
        element: {
            type: Object,
            default: null,
        },
        elementType: {
            type: String,
            default: '',
        },
    },
    data(){
        return {
            value: {},
            operationOptions: [
                {value: 1, text: "Non vérifié"},
                {value: 2, text: "Fonctionne"},
                {value: 3, text: "Hors service"},
            ],
            wearStateOptions: [
                {value: 1, text: "Non vérifié"},
                {value: 2, text: "Neuf"},
                {value: 3, text: "Bon état"},
                {value: 4, text: "Etat moyen"},
                {value: 5, text: "Mauvais état"},
            ],
            elementKeys: [],
            elementTitle: '',
            elementLabels: {},
        };
    },
    methods: {
        saveElement() {
            this.$emit('save', this.value, this.elementType)
        },
        deleteElement() {
            this.$emit('delete', this.value.index, this.elementType)
        },
        resetForm() {
            this.$emit('resetForm')
        }
    },
    watch: {
        element: {
            handler: function(val) {
                if(val) {
                    this.value = {...val}
                }
            },
            deep: true
        },
        elementType: {
            handler: function (val) {
                if (val) {
                    switch (val) {
                        case "swm":
                            this.elementKeys = ['SerialNo', 'M3', 'Operation'];
                            this.elementTitle = this.i18nT(`Relevés des compteurs eau, gaz...`)
                            this.elementLabels = {Type: this.i18nT('Type de releve'), SerialNo: this.i18nT(`No. de serie`), M3: this.i18nT(`M3`), Operation: this.i18nT(`Fonctionnement`)}
                            break;
                        case "emr":
                            this.elementKeys = ['SerialNo', 'M3', 'Operation'];
                            this.elementTitle = this.i18nT(`Relevé compteur électrique`)
                            this.elementLabels = {Type: this.i18nT('Type de releve'), SerialNo: this.i18nT(`No. de serie`), M3: this.i18nT(`KWH`), Operation: this.i18nT(`Fonctionnement`)}
                            break;
                        case "th":
                            this.elementKeys = ['SerialNo', 'M3', 'Operation'];
                            this.elementTitle = this.i18nT(`Type de chauffage`)
                            this.elementLabels = {Type: this.i18nT('Type de releve'), SerialNo: this.i18nT(`No. de serie`), M3: this.i18nT(`M3/KWH`), Operation: this.i18nT(`Fonctionnement`)}
                            break;
                        case "hw":
                            this.elementKeys = ['WearState', 'Operation'];
                            this.elementTitle = this.i18nT(`Production d’eau chaude`)
                            this.elementLabels = {Type: this.i18nT('Type production'), WearState: this.i18nT(`Etat d’usure`), Operation: this.i18nT(`Fonctionnement`)}

                            break;
                        case "key":
                            this.elementKeys = ['Count', 'Given', 'Date'];
                            this.elementTitle = this.i18nT(`Remise des clés`)
                            this.elementLabels = {Type: this.i18nT('Type cle'), Count: this.i18nT(`Nombre`), Given: this.i18nT(`Remis`), Date: this.i18nT(`Date`)}
                            break;
                        case "oth":
                            this.elementKeys = ['SerialNo', 'WearState', 'Operation'];
                            this.elementTitle = this.i18nT(`Autres aménagements`)
                            this.elementLabels = {Type: this.i18nT('Element'), SerialNo: this.i18nT(`Matériau`), WearState: this.i18nT(`Etat d’usure`), Operation: 'Fonctionnement'}
                            break;
                        default:
                            this.elementKeys = [];
                            break;
                    }
                }
            },
            immediate: true
        }
    }
}
</script>